import { Injectable, inject } from '@angular/core';
import { HttpService } from '../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFormsService {
  private readonly http = inject(HttpService);

  public async getCustomFieldsSchema(): Promise<any> {
    return await this.http.get(`api/companies/fields/schema`);
  }
}
