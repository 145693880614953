<div class="form-builder_side-panel">
  <scrollable-area [grow]="true">
    @if (!editedElement) {
      <div class="form-builder_element-groups">
        @for (group of elementGroups; track group) {
          <div class="form-builder_element-group">
            <div
              (click)="group.expanded = !group.expanded"
              [appFocusable]
              class="form-builder_element-group-name"
            >
              <span>{{ group.label | translate }}</span>
              <img
                [appAssetSrc]="
                  group.expanded ? 'assets/svg/common/arrow-up-grey.svg' : 'assets/svg/common/arrow-down-grey.svg'
                "
              />
            </div>
            @if (group.expanded) {
              @for (elem of group.elements; track elem) {
                <div
                  [appDraggable]="{ element: elem, action: 'add' }"
                  [class.not-droppable]="!isElementDraggable($any(elem))"
                  appDraggableAnchor
                  class="form-builder_element"
                >
                  <img appAssetSrc="assets/svg/custom-forms/drag-icon-white.svg" />
                  <div class="form-builder_element-icon">
                    @if (elem.icon) {
                      <img [appAssetSrc]="elem.icon" />
                    }
                  </div>
                  <span>{{ elem.label }}</span>
                </div>
              }
            }
          </div>
        }
      </div>
    }

    @if (editedElement) {
      <div class="form-builder_edit-panel">
        <button
          (click)="backToFieldsClick()"
          class="form-builder_cancel-edit-btn btn btn-link"
        >
          {{ 'formBuilder.buttons.backToFields' | translate }}
        </button>
        <h3>{{ 'common.buttons.edit' | translate }} {{ editedElement.label }}</h3>
        @if (isLogicField(editedElement) && !isCompanyForm) {
          <h4>
            {{ 'formBuilder.productNameHint' | translate }}
          </h4>
        }
        @if (isLogicField(editedElement) && isCompanyForm) {
          <h4>
            {{ 'formBuilder.companyNameHint' | translate }}
          </h4>
        }
        <field-edit-form
          #editForm
          (saveChanges)="saveEditChanges($event)"
          [element]="editedElement"
        ></field-edit-form>
      </div>
    }
  </scrollable-area>
</div>

<div class="form-builder_build-area">
  <div
    #buildArea
    [appDroppable]="onElementDrop.bind(this)"
  >
    <scrollable-area>
      <ng-template appFormHost></ng-template>
    </scrollable-area>
  </div>
</div>
