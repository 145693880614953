import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  inject,
  Input,
  ViewChild,
  type ViewContainerRef,
  ViewEncapsulation,
  output,
} from '@angular/core';
import { FormHostDirective } from '../../core/directives/form-host.directive';
import { FormElementContainerComponent } from '../components/form-element-container/form-element-container.component';
import { takeWhile } from 'rxjs/operators';
import { BasicElements, LogicFields } from '../constants/form-elements';
import { type CustomFormControl, type CustomFormDropPosition, type CustomFormElement } from '../models/form-element';
import { ControlTypes } from '../constants/form-control-types';
import { CustomFormsService } from '../custom-forms.service';
import { CustomFormFieldGroup } from '../models/enums';
import { type CustomField, CustomFieldAccessType, CustomFieldStatus } from '@clover/custom-fields/models/custom-field';
import { ModalService } from '@core/services/modal.service';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { type LogicFormField } from '../models/logic-form-field';
import { FieldEditFormComponent } from '../components/field-edit-form/field-edit-form.component';
import { FormRowComponent } from '../components/form-row/form-row.component';
import { type CustomFormValidationErrors } from '../models/form-validation-errors';
import { type CustomFieldsSchema } from '../models/custom-fields-schema';
import { TranslateModule } from '@ngx-translate/core';
import { DroppableDirective } from '../../core/directives/droppable.directive';
import { DraggableDirective } from '../../core/directives/draggable.directive';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ScrollableAreaComponent } from '../../core/components/scrollable-area/scrollable-area.component';

const MAX_ROW_LENGTH = 2;

@Component({
  selector: 'form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    FocusableDirective,
    AssetSrcDirective,
    DraggableDirective,
    FieldEditFormComponent,
    DroppableDirective,
    FormHostDirective,
    TranslateModule,
  ],
})
export class FormBuilderComponent {
  @Input() formData: CustomFormElement[] = [];
  @Input() isCompanyForm = true;
  @Input() workflowStepId: number;
  @Input() logicFields: LogicFormField[] = [];
  logicFieldsChange = output<LogicFormField[]>();
  formDataChange = output<CustomFormElement[]>();
  @ViewChild(FormHostDirective, { static: true })
  appFormHost: FormHostDirective;
  @ViewChild('buildArea') buildArea: ElementRef;
  @ViewChild('editForm') editForm: FieldEditFormComponent;
  public isLoading = false;
  public editedElement: CustomFormElement = null;
  public basicElementGroups: Array<{
    expanded: boolean;
    label: string;
    elements: CustomFormElement[];
  }> = [
      {
        expanded: true,
        label: 'formBuilder.elementGroups.basicElements',
        elements: BasicElements,
      },
    ];
  public linkedElementGroups: Array<{
    expanded: boolean;
    label: string;
    elements: CustomFormElement[];
  }> = [
      {
        expanded: false,
        label: 'formBuilder.elementGroups.publicElements',
        elements: [],
      },
      {
        expanded: false,
        label: 'formBuilder.elementGroups.privateElements',
        elements: [],
      },
      {
        expanded: false,
        label: 'formBuilder.elementGroups.confidentialElements',
        elements: [],
      },
      {
        expanded: false,
        label: 'formBuilder.elementGroups.customElements',
        elements: [],
      },
    ];
  private readonly componentFactoryResolver = inject(ComponentFactoryResolver);
  private readonly customFormsService = inject(CustomFormsService);
  private readonly modalService = inject(ModalService);
  private isAlive = true;
  private lastId = 0;
  private viewContainerRef: ViewContainerRef;
  private formRows: CustomFormControl[][] = [];
  private customFieldsSchema: CustomFieldsSchema;

  constructor() {
    this.onElementDrop = this.onElementDrop.bind(this);
  }

  public get elementGroups() {
    return this.isCompanyForm
      ? [...this.basicElementGroups, ...this.linkedElementGroups]
      : [...this.basicElementGroups];
  }

  public ngOnInit(): void {
    this.viewContainerRef = this.appFormHost.viewContainerRef;

    this.isLoading = true;

    this.loadFields().then(() => {
      if (this.formData.length > 0) {
        this.formData.forEach((elem) => {
          if (elem.id > this.lastId) {
            this.lastId = elem.id + 1;
          }
        });
      }

      const addedElements = [];
      this.formData.forEach((el) => {
        const control = ControlTypes[el.controlType];
        if (control) {
          addedElements.push({
            ...control,
            ...el,
          });
        }
      });

      addedElements.forEach((el) => {
        if (el.rowNumber === undefined || !this.formRows[el.rowNumber]) {
          this.formRows.push([el]);
        } else {
          this.formRows[el.rowNumber].push(el);
        }
      });

      this.formRows.map((row) =>
        row.sort((a, b) => {
          if (a.rowPosition < b.rowPosition) {
            return -1;
          }
          if (a.rowPosition > b.rowPosition) {
            return 1;
          }
          return 0;
        }),
      );

      this.isLoading = false;
      this.renderElements();
    });
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  public isSelectedControl(element: CustomFormControl): boolean {
    return this.editedElement ? this.editedElement.id === element.id : false;
  }

  public onElementDrop(data: { element: CustomFormControl; action: string }, event: MouseEvent): void {
    if (data.action === 'add' && !this.isElementDraggable(data.element)) {
      this.modalService.open({
        content: OkModalComponent,
        inputs: {
          isError: true,
          title: 'formBuilder.duplicateField',
          text: 'formBuilder.duplicateFieldMsg',
        },
      });
      return;
    }

    const area = this.buildArea.nativeElement as HTMLElement;
    const draggingOverElement = area.querySelector('.dragging-over[customformelementid]');
    const id = draggingOverElement ? +draggingOverElement.attributes.getNamedItem('customFormElementId').value : null;
    const direction = draggingOverElement?.attributes.getNamedItem('dropDireciton').value;
    const dropPosition = this.findDropPosition(event, direction, id);

    if (
      dropPosition.rowNumber >= 0 &&
      data.element.rowNumber !== dropPosition.rowNumber &&
      (direction === 'left' || direction === 'right') &&
      this.formRows[dropPosition.rowNumber]?.length >= MAX_ROW_LENGTH
    ) {
      return;
    }

    if (data.action === 'add') {
      this.addElement(data.element, dropPosition);
    }

    if (data.action === 'move' && id !== data.element.id) {
      this.onElementMove(data.element, dropPosition);
    }
  }

  public addElement(element: CustomFormControl, dropPosition: CustomFormDropPosition): void {
    this.lastId++;
    const newEl = {
      id: this.lastId,
      ...ControlTypes[element.controlType],
      ...element,
      ...dropPosition,
    };

    if (this.isLogicField(newEl)) {
      newEl.fieldId = this.getNewLogicFieldId();
      newEl.title = newEl.label;
      this.logicFieldsChange.emit([
        ...this.logicFields,
        {
          id: newEl.fieldId,
          controlType: newEl.controlType,
          type: newEl.type,
          title: newEl.label,
          workflowStepId: this.workflowStepId,
        },
      ]);
    }

    this.repositionControl(newEl, dropPosition);
    this.emitValue();
    this.renderElements();
  }

  public backToFieldsClick(): void {
    this.editedElement = null;
  }

  public saveEditChanges(data: any): void {
    if (this.isLogicField(this.editedElement)) {
      const logicField = this.logicFields.find((field) => field.id === this.editedElement.fieldId);
      let newLogicField: LogicFormField;

      if (!logicField) {
        newLogicField = {
          id: this.getNewLogicFieldId(),
          controlType: this.editedElement.controlType,
          type: this.editedElement.type,
          title: data.title,
          workflowStepId: this.workflowStepId,
        };
      } else {
        newLogicField = {
          ...logicField,
          title: data.title,
          workflowStepId: this.workflowStepId,
        };
      }

      if (data.options) {
        newLogicField.options = {
          items: data.options.split('\n').filter((option) => !!option),
        };
      }

      this.logicFieldsChange.emit([...this.logicFields.filter((el) => el.id !== logicField.id), newLogicField]);
      this.editedElement.title = data?.title;
    }

    this.editedElement.data = data;
    this.editedElement = null;
    this.emitValue();
    this.renderElements();
  }

  public getFormValidationErrors(): CustomFormValidationErrors {
    const errors = {
      hasMissingProps: false,
      hasInactiveFields: false,
    };

    this.formRows.forEach((row) => {
      row.forEach((el) => {
        const err = this.validateControl(el);

        switch (err) {
          case 'formBuilder.errors.fieldDeleted':
          case 'formBuilder.errors.fieldInactive':
            errors.hasInactiveFields = true;
            break;
          case 'formBuilder.errors.hasRequiredFields':
            errors.hasMissingProps = true;
            break;
          default:
            break;
        }
      });
    });

    return errors;
  }

  public isElementDraggable(element: CustomFormControl): boolean {
    if (element.fieldGroup === CustomFormFieldGroup.Basic || element.fieldGroup === CustomFormFieldGroup.Logic) {
      return true;
    }

    return !this.formRows.find((row) =>
      row.find((el) => el.fieldGroup === element.fieldGroup && el.fieldId === element.fieldId),
    );
  }

  public saveEdit(): void {
    if (this.editedElement) {
      this.editForm.save();
    }
  }

  isLogicField(element: CustomFormElement): boolean {
    return element.fieldGroup === CustomFormFieldGroup.Logic;
  }

  private async loadFields(): Promise<void> {
    this.basicElementGroups.push({
      expanded: false,
      label: 'formBuilder.elementGroups.logicFields',
      elements: LogicFields,
    });

    if (!this.isCompanyForm) {
      await Promise.resolve();
      return;
    }

    await this.customFormsService.getCustomFieldsSchema().then((res) => {
      this.customFieldsSchema = res;
      this.linkedElementGroups[0].elements = res.publicFields.map((field) =>
        this.parseField(CustomFormFieldGroup.Public, field),
      );
      this.linkedElementGroups[1].elements = res.privateFields.map((field) =>
        this.parseField(CustomFormFieldGroup.Private, field),
      );
      this.linkedElementGroups[2].elements = res.confidentialFields.map((field) => {
        return this.parseField(CustomFormFieldGroup.Confidential, field);
      });
      this.linkedElementGroups[3].elements = res.proprietaryFields
        .filter(
          (field) =>
            field.status !== CustomFieldStatus.Inactive &&
            !field.isReadonly,
        )
        .map((field) => this.parseField(CustomFormFieldGroup.Proprietary, field));
    });
  }

  private isBasicField(element: CustomFormElement): boolean {
    return element.fieldGroup === CustomFormFieldGroup.Basic;
  }

  private parseField(group: CustomFormFieldGroup, data: any): CustomFormElement {
    const controlType = ControlTypes[data.controlType];
    return {
      fieldId: data.key,
      fieldGroup: group,
      controlType: data.controlType,
      type: data.type,
      label: data.title,
      icon: controlType ? controlType.icon : '',
      title: data.title,
      data: {
        visible: true,
        required: false,
      },
    };
  }

  private emitValue(): void {
    const newData: CustomFormElement[] = [];
    this.formRows.forEach((row, rowNumber) => {
      row.forEach((el, rowPosition) => {
        newData.push({
          id: el.id,
          type: el.type,
          fieldId: el.fieldId,
          fieldGroup: el.fieldGroup,
          controlType: el.controlType,
          title: el.title,
          data: el.data,
          rowNumber,
          rowPosition,
        });
      });
    });
    this.formDataChange.emit(newData);
  }

  private renderElements(): void {
    const elementFactory = this.componentFactoryResolver.resolveComponentFactory(FormElementContainerComponent);
    const rowFactory = this.componentFactoryResolver.resolveComponentFactory(FormRowComponent);

    const area: HTMLElement = this.buildArea
      ? (this.buildArea.nativeElement as HTMLElement).querySelector('.scrollable-area')
      : null;
    const scrollPos = area?.scrollTop;

    this.appFormHost.viewContainerRef.clear();
    this.formRows.forEach((row, id) => {
      const rowComponentRef = this.viewContainerRef.createComponent(rowFactory).instance.appFormHost.viewContainerRef;

      row.forEach((el) => {
        const componentRef = rowComponentRef.createComponent(elementFactory);
        const instance = componentRef.instance;
        el.isLinked = !this.isLogicField(el) && !this.isBasicField(el);
        const schema = this.getFieldFromSchema(el);
        el.helpText = schema?.helpText;
        el.rowNumber = id;

        instance.element = el;
        instance.isSelectedFunc = this.isSelectedControl.bind(this);
        instance.error = this.validateControl(el);
        instance.deleteClick.pipe(takeWhile((_) => this.isAlive)).subscribe((elem) => {
          this.deleteElement(elem);
        });

        instance.editClick.pipe(takeWhile((_) => this.isAlive)).subscribe((elem) => {
          this.editElement(elem);
        });
      });
    });

    if (area) {
      setTimeout(() => {
        area.scrollTo(area.scrollLeft, scrollPos);
      }, 0);
    }
  }

  private validateControl(control: CustomFormControl): string {
    if (control.fieldGroup === CustomFormFieldGroup.Proprietary) {
      const fieldSchema = this.customFieldsSchema.proprietaryFields.find((el) => +el.key === +control.fieldId);

      if (!fieldSchema) {
        return 'formBuilder.errors.fieldDeleted';
      }

      if (fieldSchema?.status && fieldSchema.status === 'Inactive') {
        return 'formBuilder.errors.fieldInactive';
      }
    }

    if (control.fieldGroup === CustomFormFieldGroup.Logic) {
      let isValid = true;
      control.logicProperties.forEach((prop) => {
        if (prop.required && !control.data[prop.key]) {
          isValid = false;
        }
      });
      if (!isValid) {
        return 'formBuilder.errors.hasRequiredFields';
      }
    }

    return null;
  }

  private findDropPosition(event: MouseEvent, direction: string, elementId: number): CustomFormDropPosition {
    if (this.formRows.length === 0) {
      return { rowNumber: -1 };
    }

    if (elementId) {
      const rowNumber = this.formRows.findIndex((row) => row.findIndex((element) => element.id === elementId) !== -1);
      const rowPosition = this.formRows[rowNumber].findIndex((element) => element.id === elementId);
      return { rowNumber, direction, rowPosition };
    } else {
      const area = this.buildArea.nativeElement as HTMLElement;
      const box = area.getBoundingClientRect();

      const htmlStyles = getComputedStyle(document.querySelector('body')) as any;
      const zoom = 1 / (+htmlStyles.zoom || 1);

      return {
        rowNumber: zoom * (event.clientY || event.y) > box.top + 30 ? -1 : -2,
      };
    }
  }

  private getNewLogicFieldId(): number {
    return this.logicFields.length > 0 ? Math.max(...this.logicFields.map((x) => x.id)) + 1 : 0;
  }

  private repositionControl(el: CustomFormControl, dropPosition: CustomFormDropPosition) {
    switch (dropPosition.rowNumber) {
      case -1:
        this.formRows = [...this.formRows, [el]];
        break;
      case -2:
        this.formRows = [[el], ...this.formRows];
        break;
      default:
        switch (dropPosition.direction) {
          case 'top':
            this.formRows.splice(dropPosition.rowNumber, 0, [el]);
            break;

          case 'left':
            this.formRows[dropPosition.rowNumber].splice(dropPosition.rowPosition, 0, el);
            break;

          case 'right':
            this.formRows[dropPosition.rowNumber].splice(dropPosition.rowPosition + 1, 0, el);
            break;

          default:
            this.formRows.splice(dropPosition.rowNumber + 1, 0, [el]);
            break;
        }
        break;
    }
  }

  private editElement(elem: CustomFormElement): void {
    this.editedElement = elem;
  }

  private deleteElement(elem: CustomFormElement): void {
    if (this.editedElement) {
      return;
    }
    const currentRowid = this.formRows.findIndex((row) => row.find((element) => element.id === elem.id));
    this.formRows[currentRowid] = this.formRows[currentRowid].filter((element) => element.id !== elem.id);
    if (this.isLogicField(elem)) {
      this.logicFieldsChange.emit(this.logicFields.filter((el) => el.id !== elem.fieldId));
    }
    this.trimEmptyRows();
    this.emitValue();
    this.renderElements();
  }

  private trimEmptyRows(): void {
    this.formRows = this.formRows.filter((row) => row.length > 0);
  }

  private onElementMove(dragEl: CustomFormControl, dropPosition: CustomFormDropPosition): void {
    const currentRowid = this.formRows.findIndex((row) => row.find((element) => element.id === dragEl.id));
    this.formRows[currentRowid] = this.formRows[currentRowid].filter((element) => element.id !== dragEl.id);

    this.repositionControl(dragEl, dropPosition);
    this.trimEmptyRows();
    this.emitValue();
    this.renderElements();
  }

  private getFieldFromSchema(el: CustomFormElement): CustomField {
    let schemaGroup;
    switch (el.fieldGroup) {
      case CustomFormFieldGroup.Proprietary:
        schemaGroup = this.customFieldsSchema.proprietaryFields;
        break;
      case CustomFormFieldGroup.Private:
        schemaGroup = this.customFieldsSchema.privateFields;
        break;
      case CustomFormFieldGroup.Confidential:
        schemaGroup = this.customFieldsSchema.confidentialFields;
        break;
      default:
        schemaGroup = [];
        break;
    }
    return schemaGroup.find((field) => field.key === el.fieldId);
  }
}
